import React from "react"
import Fade from "../Shared/Animation/Fade"
import TrailButton from "../Shared/TrailButton"

const SmallBanner = ({ data }) => {
  return (
    <div className="homeSmallBanner flex flex-col py-8 md:py-11 bg-blue-light px-4 md:px-8">
      <div className="container flex justify-center text-center">
        <Fade className="flex flex-col">
          <div
            className="text-editor mx-auto w-12/12 lg:w-8/12 xl:w-7/12"
            dangerouslySetInnerHTML={{ __html: data }}
          />
          <div className="mt-3">
            <TrailButton id="HomeTryFree" type="tryFree" text="Try for Free" />
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default SmallBanner
