import React from "react"

const ArrowVariant = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 15 20.518">
            <g transform="translate(15 -4.482) rotate(90)"><path d="M4.482,7.414c.159-.476.541-.567,1.007-.567,9.132,0,7.471-.028,16.6-.028h.457c-.131-.135-.208-.22-.29-.3L16.835,1.336c-.058-.055-.118-.108-.172-.166A.66.66,0,0,1,16.639.2a.731.731,0,0,1,1.013.009c.069.057.133.12.2.182L24.618,6.85c.506.483.509.811.011,1.287L17.754,14.7a.756.756,0,0,1-1.078.127.626.626,0,0,1-.113-.868,2.685,2.685,0,0,1,.3-.326q2.7-2.581,5.4-5.161c.077-.073.15-.151.284-.286h-.449c-9.142,0-7.491.03-16.633.034a.952.952,0,0,1-.991-.516Z" transform="translate(0 -0.001)" /></g>
        </svg>
    )
}

export default ArrowVariant