import React from "react"
import Image from "../Shared/Image"


const AboutUs = ({ data }) => {
    return (
        <div className="gradientBlue">
            <div className="main-container container flex flex-col md:flex-row justify-between items-center gap-10 py-16 md:py-32 px-4">
                <div className="w-full md:w-5/12">
                    <div className="text-editor" dangerouslySetInnerHTML={{ __html: data.textTwoCol }} />
                </div>
                <div className="flex flex-col w-full md:w-7/12 mx-auto">
                    <Image
                        placeholder="none"
                        media={data.imageTwoCol}
                        className="h-auto obj-cont-important"
                    />
                    <div className="mt-4 font-medium text-center text-sm">
                        {data?.imageTwoCol?.caption}
                    </div>
                </div>
            </div>
            <div
                className="road-map-seciton"
                dangerouslySetInnerHTML={{ __html: data.roadMapSection }}>
            </div>
            <div className="pb-36 pt-6 sm:pt-0">
                <div className="container grid gap-2 sm:gap-12 lg:gap-24 grid-cols-1 sm:grid-cols-2 px-4">
                    <div>
                        <div className="text-editor md:text-center lg:px-20 pb-6 md:pb-0" dangerouslySetInnerHTML={{ __html: data.contentLeft }} />
                    </div>
                    <div>
                        <div className="text-editor md:text-center lg:px-20" dangerouslySetInnerHTML={{ __html: data.contentRight }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
