import React from "react"

const PlayIcon = () => {
  return (
    <img src={require(`../../images/play.svg`).default}
      onMouseOver={e => (e.currentTarget.src = require(`../../images/play-hover.svg`).default)}
      onMouseOut={e => (e.currentTarget.src = require(`../../images/play.svg`).default)}
      alt="play"
    />
  )
}

export default PlayIcon