import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Banner from "./Banner";
import TrustBox from "./TrustBox";
import SmallBanner from "./SmallBanner.js";
import TwoColContent from "../Shared/TwoColContent";
import Fade from "../Shared/Animation/Fade";
import ArrowVariant from "../icons/arrow-variant";
import scrollTo from 'gatsby-plugin-smoothscroll';
import quote1 from "../../images/quotes.svg";
import quote2 from "../../images/quotes-1.svg";
import { Carousel } from "react-responsive-carousel";

const Home = ({ data }) => {

    const bannerData = {
        homeBannerContent: data.homeBannerContent,
        homeBannerMedia: data.homeBannerMedia,
        homeBannerParagraph: data.homeBannerParagraph,
    }
    
    const trustData = {
        trustUsTitle: data.trustUsTitle,
        trustUsLogo: data.trustUsLogo,
    }

    const renderIndicator = (clickHandler, isSelected, index, label) => {
        return (
            <button className={`carousel-indicator ${isSelected ? "selected" : "diselected"}`} onClick={clickHandler}></button>
        )
    }

    return (
        <section id="homePage">
            <Banner data={bannerData} />
            <TrustBox data={trustData} />
            {data.homeTextBox &&
                <Fade className="container pb-12 md:pb-24 px-4">
                    <div className="text-editor mx-auto w-full lg:w-9/12 short-desc" dangerouslySetInnerHTML={{ __html: data.homeTextBox }} />
                    <div className="flex justify-center pt-8">
                        <button onClick={() => scrollTo('#homeTwoCol0')} className="flex items-center rounded-lg bg-transparent text-blue font-medium text-lg py-2 px-3 md:px-5 border-2 border-blue focus:outline-none">
                            <span className="mr-2">
                                Discover How
                            </span>
                            <ArrowVariant height="21" fill="#48639C" />
                        </button>
                    </div>
                </Fade>
            }
            <TwoColContent data={data.homeTwoCol} />
            <SmallBanner data={data.homeSmallBanner} />
            <div className="bg-white pt-4 md:pt-0">
                <Fade>
                    <Carousel
                        autoPlay={true}
                        interval={10000}
                        showArrows={false}
                        showStatus={false}
                        transitionTime={1000}
                        infiniteLoop={true}
                        renderIndicator={renderIndicator}
                        emulateTouch={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={10}>
                        {
                            data?.Testimonials?.length > 0 &&
                            data.Testimonials.map(testimonial => { return (
                                <div className="container py-10 md:py-16 lg:py-20 lg:w-9/12 flex flex-col ta-l">
                                    <div className="md:px-11 px-8">
                                        <img src={quote1} alt="" className="wu db" />
                                        <div
                                        className="text-editor home-quote ml-11 my-8 sm:text-xl text-base "
                                        dangerouslySetInnerHTML={{ __html: testimonial.Body }}
                                        />
                                        <img className="float-right wu db" src={quote2} alt="" />
                                    </div>
                                    <div
                                        className="text-editor md:ml-11 ml-8"
                                        dangerouslySetInnerHTML={{ __html: testimonial.Author }}
                                        />
                                </div>
                            )})
                        }
                    </Carousel>
                </Fade>
            </div>
        </section>
    );
};


export default Home