import React from "react"
import { graphql } from 'gatsby'
import AboutUs from "../components/AboutUsPage/AboutUs"
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";


const About = ({ data }) => {

  const dataAbout = data.allStrapiAboutUs.edges[0].node;

  return (
    <>
      <SEO seo={dataAbout.metaData} />
      <LayoutHeadFoot>
        <AboutUs data={dataAbout} />
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query AboutQuery {
    allStrapiAboutUs {
      edges {
        node {
          textTwoCol
          imageTwoCol {
            alternativeText
            caption
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                    formats: [AUTO]
                  )
              }
            }
          }
          roadMapSection
          contentLeft
          contentRight
          metaData {
            metaTitle
            metaDescription
            shareImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }`

export default About

