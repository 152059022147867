import React from "react"
import { graphql } from "gatsby"
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import Home from "../components/HomePage/Home";
import SEO from "../components/seo";

const Index = ({ data }) => {

  const homeData = data.allStrapiHome.edges[0].node;

  return (
    <>
      <SEO seo={homeData.metaData} />
      <LayoutHeadFoot>
        <Home data={homeData} />
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query HomeQuery {
  allStrapiHome {
    edges {
      node {
        metaData {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              publicURL
            }
          }
        }
        homeBannerContent
        homeBannerParagraph
        homeBannerMedia {
          mime
          alternativeText
          url
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                  formats: [AUTO]
                )
            }
          }
        }
        homeTextBox 
        homeTwoCol {
          content
          id
          image {
            alternativeText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO]
                )
              }
            }
          }
        }
        homeSmallBanner
        trustUsTitle
        trustUsLogo {
          urlLogo
          image {
            alternativeText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO]
                )
              }
            }
          }
        }
        Testimonials {
          Body
          Author
        }
      }
    }
  }
}`

export default Index


