import React, { useState } from "react"
import SlideUp from "../Shared/Animation/SlideUp";
import Image from "../Shared/Image";
import Video from "../elements/video";
import TrailButton from "../Shared/TrailButton";
import VideoModal from "../Shared/VideoModal";
import PlayIcon from "../icons/play";

const Banner = ({ data }) => {

    const [showModal, setShowModal] = useState(false);

    const playVideo = () => {
        setShowModal(!showModal)
    }

    return (
        <div className="homeBanner relative flex flex-col md:flex-row items-center">
            <div className="lg:container px-4 mx-auto mb-8 md:mb-0 items-center flex flex-wrap">
                <SlideUp
                    className="w-full mt-4 md:mt-0 md:w-5/12 pr-0 md:pr-10 text-center md:text-left">
                    <div className="mb-4">
                        <div className="text-editor" dangerouslySetInnerHTML={{ __html: data.homeBannerContent }} />
                    </div>
                    <div
                        className="text-editor homeBannerText flex flex items center"
                        dangerouslySetInnerHTML={{ __html: data.homeBannerParagraph }}
                    />
                    <div className="mt-8 md:mt-12">
                        <TrailButton type="default" text="Get Started" id="homeGetstarted" />
                    </div>
                </SlideUp>
                {/* Media section */}
                <div className="w-full md:w-7/12 md:mt-0 mt-6 text-center md:pr-4">
                    {data.homeBannerMedia.mime.startsWith("image") && <Image media={data.homeBannerMedia} className="object-contain mx-auto obj-cont-important" />}
                    {data.homeBannerMedia.mime.startsWith("video") && (
                        <>
                            <div className="shadow">
                                <button className="video-button w-full relative flex items-center justify-center focus:outline-none text-blue-dark hover:text-blue transition-colors"
                                    onClick={playVideo}>
                                    <Video
                                        media={data.homeBannerMedia}
                                        className="w-full"
                                        controls={false}
                                    />
                                    <div className="absolute">
                                        <PlayIcon width="70" />
                                    </div>
                                </button>
                            </div>
                            {showModal &&
                                <VideoModal showModal={showModal} setShowModal={setShowModal}>
                                    <Video
                                        media={data.homeBannerMedia}
                                        className=""
                                        controls={true}
                                        autoPlay={true}
                                    />
                                </VideoModal>
                            }
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Banner;
