import React from "react"
import PropTypes from "prop-types";
import { mediaPropTypes } from "@/utils/types";

const Video = ({
  media,
  className,
  controls = true,
  autoPlay = false,
}) => {
  const fullVideoUrl = media.localFile.publicURL;

  return (
    <video
      className={className}
      controls={controls}
      autoPlay={autoPlay}
    >
      <source src={fullVideoUrl} type={media.mime} />
    </video>
  );
};

Video.propTypes = {
  media: mediaPropTypes,
  className: PropTypes.string,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
};

export default Video;
